import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APP_PLATFORM, DEFAULT_SLOT_STORE_TEMPLATE, MAX_SLOT } from "../../config";
import { EmptySlotOption, Platform, Slot } from "../../models";
import { createSlot, deleteSlot, updateSlot as updateSlotApi } from "../../services/conditions.service";
import { RootState } from "../store";
import { fetchConditionsAndSlotsAsync } from "./appSlice";
import { PageType } from "@reconvert/reconvert-utils";

interface SlotState {
  slots: Slot[];
  error: string | null;
  loading: boolean;
  canCreateSlot: boolean;
  creatingSlot: boolean;
}

const initialState: SlotState = {
  slots: [],
  error: null,
  loading: false,
  canCreateSlot: true,
  creatingSlot: false,
};

export const createSlotAsync = createAsyncThunk("slot/create", async (_, { getState }) => {
  const state = getState() as RootState;
  const activePage = state.app.activePage;
  const platformStoreId = state.app.platformStoreId;
  const selectedConditionId = state.conditions.selectedConditionId;

  const data = {
    name: "Slot Name",
    conditionId: selectedConditionId,
    location: activePage,
  };

  const response = await createSlot(data);

  if (!response.success) {
    throw new Error(response.message || "Error in creating slot");
  }

  response.platformStoreId = platformStoreId;

  return response;
});

export const updateSlotAsync = createAsyncThunk("slot/update", async (data: { id: string; body: any }) => {
  const response = await updateSlotApi(data.id, data.body);

  if (!response.success) {
    throw new Error(response.message || "Error in updating slot");
  }

  return {
    ...response,
    ...data,
  };
});

export const deleteSlotAsync = createAsyncThunk("slot/delete", async (slotNo: number, { getState }) => {
  const state = getState() as RootState;
  const activePage = state.app.activePage;
  const response = await deleteSlot(slotNo, activePage!.toString());

  if (!response.success) {
    throw new Error(response.message || "Error in deleting slot");
  }

  return { ...response, slotNo, activePage, platformStoreId: state.app.platformStoreId };
});

const checkIfCanCreateSlotState = (slots: Slot[], activePage: PageType, platformStoreId:string) => {
  let canCreateSlot = slots.length < MAX_SLOT;

  if (platformStoreId === DEFAULT_SLOT_STORE_TEMPLATE) {
    return canCreateSlot
  }

  if (
    activePage === PageType.POST_PURCHASE_PAGE1 ||
    activePage === PageType.POST_PURCHASE_PAGE2 ||
    APP_PLATFORM === Platform.Wix
  ) {
    canCreateSlot = false;
  }

  return canCreateSlot;
};

const slotSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {
    setCreatingSlot: (state, action: PayloadAction<boolean>) => {
      state.creatingSlot = action.payload;
    },
    updateSlot: (state, action: PayloadAction<{ id: string; name?: string; emptySlotOption?: EmptySlotOption }>) => {
      const slot = state.slots.find((slot: Slot) => slot.id === action.payload.id);

      if (slot) {
        slot.name = action.payload?.name || slot.name;
        slot.emptySlotOption = action.payload?.emptySlotOption || slot.emptySlotOption;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchConditionsAndSlotsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(fetchConditionsAndSlotsAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.slots = (action.payload.slots || []).map((slot: any) => ({ ...slot, id: slot._id }));
        state.slots.sort((a: any, b: any) => a.slotNo - b.slotNo);

        const activePage = action.payload.activePage;
        const platformStoreId = action.payload.platformStoreId;

        state.canCreateSlot = checkIfCanCreateSlotState(state.slots, activePage, platformStoreId);
        state.loading = false;
      })
      .addCase(fetchConditionsAndSlotsAsync.rejected, (state, error) => {
        state.error = error?.error?.message || "";
        state.loading = false;
      })
      .addCase(createSlotAsync.fulfilled, (state, action: PayloadAction<any>) => {
        const activePage = action.payload.data.location;
        const platformStoreId = action.payload.data.platformStoreId;

        state.slots = [...state.slots, action.payload.data];
        state.slots.sort((a: any, b: any) => a.slotNo - b.slotNo);
        state.canCreateSlot = checkIfCanCreateSlotState(state.slots, activePage, platformStoreId);
        state.creatingSlot = false;
      })
      .addCase(createSlotAsync.rejected, (state, error) => {
        state.creatingSlot = false;
      })
      .addCase(updateSlotAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const slotIndex = state.slots.findIndex((slot: any) => slot.id === action.payload.data.id);

          if (slotIndex > -1) {
            state.slots[slotIndex] = {
              ...state.slots[slotIndex],
              ...action.payload.data,
            };
          }
        }
      })
      .addCase(deleteSlotAsync.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          const activePage = action.payload.activePage;
          const platformStoreId = action.payload.platformStoreId;

          state.slots = state.slots.filter((slot: any) => slot.slotNo !== action.payload.slotNo);
          state.canCreateSlot = checkIfCanCreateSlotState(state.slots, activePage, platformStoreId);
        }
      });
  },
});

export const selectSlotsState = (state: RootState) => state.slots;

export const { setCreatingSlot, updateSlot } = slotSlice.actions;

export default slotSlice.reducer;
