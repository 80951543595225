import { AbsoluteCenter, Box, Divider, Flex, Input } from "@chakra-ui/react";
import { Typography, useToast } from "@reconvert/react-ui-component";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAppState } from "../../app/slice/appSlice";
import useSlots from "../../hooks/useSlots";
import { Slot } from "../../models";
import useUnsavedBar, { PreventActionType, PreventActionTypeObject } from "../../hooks/useUnsavedBar";
import { updateSlot } from "../../app/slice/slotSlice";
import { PageType } from "@reconvert/reconvert-utils";
import { DEFAULT_SLOT_STORE_TEMPLATE } from "../../config";

const SlotHeader = ({ slot }: { slot: Slot }) => {
  const { t } = useTranslation();
  const { activePage, platformStoreId } = useAppSelector(selectAppState);
  const { error } = useToast();
  const { handleSlotNameUpdate } = useSlots();
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState(slot?.name);
  const [preserveName, setPreserveName] = useState(slot?.name);
  const dispatch = useAppDispatch();
  const { pushUnsavedChanges, preventAction } = useUnsavedBar();

  useEffect(() => {
    setName(slot?.name);
    setPreserveName(slot?.name);
  }, [slot?.name]);

  const toggleEdit = () => {
    preventAction(()=>{
      if (!name) {
        setName(preserveName);
        setIsEdit(!isEdit);
        return error(t("Slot name can't be empty"));
      }
  
      if (isEdit) {
        pushUnsavedChanges({
          id: "slotNameChange",
          type: "SLOT",
          saveAction: () => handleSlotNameUpdate(slot.id, slot.name, name),
          discardAction: () => {
            setName(slot.name);
            dispatch(updateSlot({ id: slot.id, name: slot.name }));
          },
        });
  
      }
  
      setIsEdit(!isEdit);
    }, PreventActionTypeObject.INLINE_CHANGE as PreventActionType);

  };

  const handleNameChange = (e: any) => {
    if (e.target.value) {
      setPreserveName(e.target.value);
    }

    setName(e.target.value);
  };

  const getSlotName = useCallback(() => {
    if(platformStoreId === DEFAULT_SLOT_STORE_TEMPLATE) return `${t("Slot")} ${slot?.slotNo}`;

    switch (activePage) {
      case PageType.POST_PURCHASE_PAGE1:
        return t("1st page");
      case PageType.POST_PURCHASE_PAGE2:
        return t("2nd page");
      default:
        return `${t("Slot")} ${slot?.slotNo}`;
    }
  }, [platformStoreId, t, slot?.slotNo, activePage]);

  return (
    <Flex mt={slot.slotNo === 1 ? 4 : "24px"} direction={"column"} sx={{ width: "100%", padding: "24px 0" }}>
      <Box marginBottom={"24px"} position="relative">
        <Divider color={"#CBD5E0"} p={0} />
        <AbsoluteCenter bg={"#fbfcfe"} px="2">
          <Typography textAlign={"center"} variant="h2" fontWeight={500}>
            {getSlotName()}
          </Typography>
          {isEdit ? (
            <Input
              htmlSize={name.length}
              width="auto"
              onChange={handleNameChange}
              value={name}
              size={"xs"}
              onBlur={toggleEdit}
              textAlign={"center"}
            />
          ) : (
            <Typography onClick={toggleEdit} textAlign={"center"} variant="subheading2">
              {name}
            </Typography>
          )}
        </AbsoluteCenter>
      </Box>
    </Flex>
  );
};

export default SlotHeader;
