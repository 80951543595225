import { useCallback, useEffect, useRef, useState } from "react";
import { Platform, ProductData } from "../models";
import { getProduct, getProductCollection } from "../services/conditions.service";
import { APP_PLATFORM } from "../config";

interface SearchProductReturnType {
  searching: boolean;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  products: ProductData[];
  selectedProductIdObj: Record<string, string[]>;
  handleProductSelect: (productId: string, variantIds: string[], isChecked: boolean) => void;
}

const useSearchProduct = (value: Record<string, string[]>, conditionFact: string): SearchProductReturnType => {
  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState<ProductData[]>([]);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const debounceTime = useRef<number>(0);
  const [selectedProductIdObj, setSelectedProductIdObj] = useState<Record<string, string[]>>(
    JSON.parse(JSON.stringify(value)),
  );

  const fetchProduct = useCallback(async (searchTerm: string) => {
    setSearching(true);
    try {
      let response;

      switch (conditionFact) {
        case "SPECIFIC_PRODUCT":
          response = await getProduct({
            q: searchTerm,
            limit: 250,
            fields: ["variants"],
            onlyActive: 1,
          });
          break;

        case "PRODUCT_COLLECTION":
          response = await getProductCollection({
            limit: APP_PLATFORM === Platform.Wix ? 100 : 250,
            searchQuery: searchTerm,
          });
          break;
      }

      const regex = /^(0|-)+$/;

      if (response?.products) {
        const products = response.products.map((item: any) => ({
          ...item,
          id: item.id.split("/").pop() || "",
          variants: item.variants.map((variant: any) => ({
            ...variant,
            //Below variant id condition is only for wix, because wix not creating variant id for default variant
            //so we will set product id when there is no variant id available
            id: regex.test(variant.id.split("/").pop()) ? item.id.split("/").pop() : variant.id.split("/").pop() || "",
          })),
        }));

        setProducts(products);
      }

      if (response?.collections) {
        const products = response.collections.map((item: any) => ({
          ...item,
          id: item.id.split("/").pop() || "",
          variants: [],
          images: [],
        }));

        setProducts(products);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setSearching(false);
    }
  }, [conditionFact]);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      fetchProduct(searchTerm);
      debounceTime.current = 500;
    }, debounceTime.current);

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [searchTerm, fetchProduct]);

  const handleProductSelect = useCallback(
    (productId: string, variantIds: string[], isChecked: boolean) => {
      setSelectedProductIdObj(prevSelectedProductIdObj => {
        const newSelectedProductIdObj = { ...prevSelectedProductIdObj };

        switch (conditionFact) {
          case "SPECIFIC_PRODUCT":
          default:
            if (isChecked) {
              return {
                ...prevSelectedProductIdObj,
                [productId]: [...(prevSelectedProductIdObj[productId] || []), ...variantIds],
              };
            }

            const filteredVariants = (prevSelectedProductIdObj[productId] || []).filter(
              variantId => !variantIds.includes(variantId),
            );

            if (filteredVariants.length === 0) {
              delete newSelectedProductIdObj[productId];
              return newSelectedProductIdObj;
            }

            return {
              ...prevSelectedProductIdObj,
              [productId]: [...filteredVariants],
            };

          case "PRODUCT_COLLECTION":
            if (isChecked) {
              return {
                ...prevSelectedProductIdObj,
                [productId]: variantIds,
              };
            }

            const filteredProductIds = (prevSelectedProductIdObj[productId] || []).filter(
              prevProductId => prevProductId !== variantIds[0],
            );

            if (filteredProductIds.length === 0) {
              delete newSelectedProductIdObj[productId];
              return newSelectedProductIdObj;
            }

            return {
              ...prevSelectedProductIdObj,
              [productId]: [...filteredProductIds],
            };
        }
      });
    },
    [conditionFact],
  );

  return {
    searching,
    searchTerm,
    setSearchTerm,
    products,
    selectedProductIdObj,
    handleProductSelect,
  };
};

export default useSearchProduct;
